<template>
<div class="app-container">
  <el-col :sm="24" :md="16" >
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>Editar encuesta</span>
      </div>
      <div class="text item">
        <ValidationObserver ref="form" v-slot="{ invalid }">
          <el-form label-position="top" label-width="100px" :model="form">
               <el-form-item label="Lugar de trabajo">
                 <select v-model="form.workPlace" multiple class="width-100">
                    <option v-for="itemWork in listItemsWorkplace" :key="itemWork.id">
                      {{itemWork}}
                    </option>
                  </select>
              </el-form-item>
              <el-form-item label="Título" size="mini">
                <ValidationProvider name="titulo" rules="required|min:1|max:100" v-slot="{ errors }">
                  <el-input v-model="form.title"></el-input>
                  <span>{{ errors[0] }}</span>
                </ValidationProvider>
              </el-form-item>
              <el-form-item label="Descripción">
                <tinyeditor v-model="form.description"
                  api-key='epme9s1ejp9yit6h3lokaj05yohsvjm61r9y7kdrvm0wm9ex'
                    :init="{
                      height: 300,
                      plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount'
                      ],
                      toolbar:
                        'undo redo | formatselect | bold italic backcolor | \
                        alignleft aligncenter alignright alignjustify | \
                        bullist numlist outdent indent | removeformat | help'
                    }"
                  />
              </el-form-item>
              <el-form-item label="Duración aproximada" size="mini">
                  <ValidationProvider name="decription short" rules="required|min:1" v-slot="{ errors }">
                  <el-input v-model="form.duration"></el-input>
                  <span>{{ errors[0] }}</span>
                </ValidationProvider>
              </el-form-item>
              <el-form-item label="Imagen">
                <div v-if="form.image">
                  <el-image :src="$urlGlobalApi + form.image">
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                  </el-image>
                </div>
                <el-upload
                  action=""
                  drag
                  name="image"
                  :http-request="postImage"
                  :on-success="handleAvatarSuccess"
                  multiple>
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">Suelta tu foto aquí o <em>haz clic para cargar</em></div>
                  <div slot="tip" class="el-upload__tip">Solo archivos jpg/png con un tamaño menor de 500kb (800 x 450)</div>
                </el-upload>
              </el-form-item>
              <el-card shadow="never" class="card-notification">
                <el-form-item
                  class="size-tips"
                  v-for="(params, index) in form.questions"
                  :label="'Pregunta ' + index"
                  :key="params.key"
                  :prop="'questions.' + index + '.answers'"
                >
                <el-col :span="10">
                  <span>Pregunta</span>
                  <ValidationProvider name="pregunta" rules="required" v-slot="{ errors }">
                    <el-input size="mini" v-model="params.question" placeholder="Pregunta"></el-input>
                    <span>{{ errors[0] }}</span>
                  </ValidationProvider>
                </el-col>
                <el-col :span="11">
                  <span>Posibles respuestas</span>
                  <ValidationProvider name="respuestas" rules="required" v-slot="{ errors }">
                    <el-input size="mini" v-model="params.answers" placeholder="Nómina,Noticias,Encuestas"></el-input>
                    <span>{{ errors[0] }}</span>
                  </ValidationProvider>
                    <span>Ingresar las respuestas separadas por coma <strong>Ejemplo:</strong> Nómina,Noticias,Encuestas</span>
                </el-col>
                <el-col :span="3">
                  <el-button class="margin-top-20" size="mini" @click.prevent="removeInput(params)">Eliminar</el-button>
                </el-col>
                </el-form-item>
                <el-form-item>
                  <el-button size="mini" @click="addInput">Agregar pregunta</el-button>
                </el-form-item>
              </el-card>
              <el-form-item label="Categoria">
                <ValidationProvider name="categoria" rules="required" v-slot="{ errors }">
                  <el-select
                  class="width-100"
                  size="mini"
                  v-model="form.category"
                  multiple
                  filterable
                  allow-create
                  placeholder="Comunicación">
                    <el-option
                      v-for="itemT in optionsTags"
                      :key="itemT.value"
                      :label="itemT.label"
                      :value="itemT.value">
                    </el-option>
                  </el-select>
                  <span>{{ errors[0] }}</span>
                </ValidationProvider>
              </el-form-item>
              <el-form-item label="Orden" size="mini">
                <ValidationProvider name="orden" rules="required" v-slot="{ errors }">
                  <!-- <el-input v-model="form.order"></el-input> -->
                  <el-input-number v-model="form.order" controls-position="right" :min="0"></el-input-number>
                  <span>{{ errors[0] }}</span>
                </ValidationProvider>
              </el-form-item>
              <el-form-item label="Fecha de publicación">
                <ValidationProvider name="fecha de publicación" rules="required" v-slot="{ errors }">
                  <el-date-picker
                    size="mini"
                    v-model="form.datePublication"
                    type="date"
                    format="dd-MM-yyyy"
                    placeholder="Fecha">
                  </el-date-picker>
                  <span>{{ errors[0] }}</span>
                </ValidationProvider>
              </el-form-item>
              <el-form-item label="Publicar">
                <el-switch
                  v-model="form.status"
                  active-color="#13ce66"
                  inactive-color="#ff4949">
                </el-switch>
              </el-form-item>
              <el-form-item>
              <el-button
                @click.stop.prevent="onUpdate"
                type="primary"
                size="small"
                :disabled="invalid"
                :loading="loading"
                icon="el-icon-upload2">
                Actualizar</el-button>
              </el-form-item>
          </el-form>
        </ValidationObserver>
      </div>
    </el-card>
  </el-col>
</div>
</template>
<script>
import { getSurvey, updateSurvey } from '@/api/surveys'
import { uploadImageFile } from '@/api/image'
import { getWorkplace } from '@/api/listItems'

export default {
  data () {
    return {
      form: {
        workPlace: [],
        title: '',
        description: '',
        duration: '',
        image: '',
        order: 0,
        questionquestions: [],
        answersquestions: [],
        category: [],
        datePublication: '',
        status: true,
        questions: [
          {
            key: 1,
            question: '',
            answers: ''
          }
        ]
      },
      optionsTags: [{
        value: 'Liderazgo',
        label: 'Liderazgo'
      }, {
        value: 'Comunicación interna',
        label: 'Comunicación interna'
      }, {
        value: 'General',
        label: 'General'
      }, {
        value: 'Reconocmientos',
        label: 'Reconocmientos'
      }, {
        value: 'Consejo Editorial',
        label: 'Consejo Editorial'
      }, {
        value: 'Noticia',
        label: 'Noticia'
      }],
      loading: false,
      listItemsWorkplace: {}
    }
  },
  created () {
    this.fetchData()
    this.listWorkplace()
  },
  methods: {
    async fetchData () {
      this.loading = true
      await getSurvey(this.$route.params._id)
        .then((response) => {
          this.form = response.data
          console.log(response.data)
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async listWorkplace () {
      this.loading = true
      await getWorkplace()
        .then(response => {
          // console.log(response.data)
          this.listItemsWorkplace = response.data
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async onUpdate () {
      await updateSurvey(this.$route.params._id, this.form)
        .then((response) => {
          // let res = response.data
          this.$message({
            showClose: true,
            message: '¡Encuesta actualizada!',
            type: 'success'
          })
          this.$router.push({ name: 'surveys' })
        })
        .catch(this.responseCatch)
        // .finally(() => (this.loading = false))
    },
    async postImage (param) {
      this.loading = true
      const formData = new FormData()
      formData.append('image', param.file)
      formData.append('folder', 'img/cursos')
      formData.append('width', '800')
      formData.append('height', '450')
      formData.append('title', 'Surveys-image')
      formData.append('alt', 'encuesta-image')
      uploadImageFile(formData).then((response) => {
        console.log(response)
        param.onSuccess(response.data)
      })
        .catch(response => {
          console.log(response)
          param.onError()
        })
        .finally(() => (this.loading = false))
    },
    handleAvatarSuccess (res, file) {
      this.form.image = res.path
    },
    removeInput (item) {
      var index = this.form.questions.indexOf(item)
      if (index !== -1) {
        this.form.questions.splice(index, 1)
      }
    },
    addInput () {
      this.form.questions.push({
        key: Date.now(),
        question: '',
        answers: ''
      })
    }
  }
}
</script>
